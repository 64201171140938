
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppInitialProps, AppProps } from 'next/app';
import { UserProfile, UserProvider } from '@auth0/nextjs-auth0/client';
import { CallbackApiProvider, ToastElement, WorkspaceProvider } from '@simetrikinc/desyk';
import '@simetrikinc/desyk/styles/globals/all.sass';
import '@simetrikinc/desyk/styles/globals/_toast.sass';
import '@simetrikinc/desyk/styles/globals/_tooltip.sass';
import '@simetrikinc/desyk/dist/style.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { NextPage } from 'next';
import { FilterProvider } from '@/contexts/FilterContext/FilterContext';
import environmentConstant from '@/utils/tools/environmentConstant';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: AppInitialProps['pageProps'] & {
    user?: UserProfile;
  };
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { workspaceCookieName, auth0CookieDomain } = environmentConstant;
  const { user } = pageProps;
  const [domain, setDomain] = useState('');
  const getLayout = Component.getLayout || ((page) => page);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDomain(window.location.hostname);
    }
  }, [domain]);

  return (
    <UserProvider user={user}>
      <WorkspaceProvider workspaceCookieSettings={{ domain: auth0CookieDomain, name: workspaceCookieName, deleteOldCookie: true }}>
        <CallbackApiProvider>
          <QueryClientProvider client={queryClient}>
            <FilterProvider>{getLayout(<Component {...pageProps} />)}</FilterProvider>
            <ToastElement />
          </QueryClientProvider>
        </CallbackApiProvider>
      </WorkspaceProvider>
    </UserProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  