/* eslint-disable import/no-unresolved */
import { DateRange } from '@simetrikinc/desyk/dist/Components/DatePicker/DatePickerFilter/types';
import React, { createContext, useContext, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { stateFiltersType } from 'types/main/filters';

interface FilterContextType {
  stateFiltersDate: { filter: number | undefined; date: DateRange | undefined };
  setStateFiltersDate: Dispatch<SetStateAction<{ filter: number | undefined; date: DateRange | undefined }>>;
  queryParamsState: { [key: string]: unknown } | undefined;
  setQueryParamsState: Dispatch<SetStateAction<{ [key: string]: unknown } | undefined>>;
  enableFilterButton: boolean;
  setEnableFilterButton: Dispatch<SetStateAction<boolean>>;
  stateFilters: stateFiltersType | undefined;
  setStateFilters: Dispatch<SetStateAction<stateFiltersType | undefined>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [stateFilters, setStateFilters] = useState<stateFiltersType | undefined>(undefined);
  const [stateFiltersDate, setStateFiltersDate] = useState<{ filter: number | undefined; date: DateRange | undefined }>({
    filter: undefined,
    date: { startDate: null, endDate: null },
  });
  const [queryParamsState, setQueryParamsState] = useState<{ [key: string]: unknown } | undefined>(undefined);
  const [enableFilterButton, setEnableFilterButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const value = useMemo(
    () => ({
      stateFiltersDate,
      setStateFiltersDate,
      queryParamsState,
      setQueryParamsState,
      enableFilterButton,
      setEnableFilterButton,
      stateFilters,
      setStateFilters,
      currentPage,
      setCurrentPage,
    }),
    [stateFiltersDate, queryParamsState, enableFilterButton, stateFilters, currentPage],
  );
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
