var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fe-integrations@1.0.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import config from './src/utils/tools/environmentConstant';

const { sentry } = config;

if (sentry.environment && sentry.DSN && sentry.environment === 'PRODUCTION') {
  Sentry.init({
    dsn: sentry.DSN,
    environment: sentry.environment,
    tracesSampleRate: sentry.tracesSampleRate,
    release: `${sentry.project}@${sentry.packageVersion}`,
    replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
    replaysSessionSampleRate: sentry.replaysSessionSampleRate,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}
