const environmentConstant = {
  auth0: {
    namespace: process.env.AUTH0_NAMESPACE ?? 'https://app.simetrik.com',
    baseUrl: process.env.AUTH0_BASE_URL,
    issuerBaseUrl: process.env.AUTH0_ISSUER_BASE_URL,
    clientSecret: process.env.AUTH0_CLIENT_SECRET,
    clientId: process.env.AUTH0_CLIENT_ID,
  },
  sentry: {
    DSN: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    packageVersion: process.env.SENTRY_RELEASE ?? '',
    project: process.env.SENTRY_PROJECT ?? 'fe-integrations',
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE ?? '0.01'),
    replaysSessionSampleRate: parseFloat(process?.env?.SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? '0.01'),
    replaysOnErrorSampleRate: parseFloat(process?.env?.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? '0.01'),
  },
  remotes: {
    baseURL: process?.env?.API_URL ?? '',
    datahubBaseUrl: process.env.DATAHUB_API_BASE_URL ?? '',
    authenticationBaseUrl: process.env.AUTHENTICATION_API_BASE_URL ?? '',
    microFrontend: process?.env?.REMOTE_MICRO_FRONTEND_HOST ?? '',
    actionHistoryBaseUrl: process.env.ACTION_HISTORY_BASE_URL ?? '',
    redirectUrl: process?.env?.REDIRECT_URL ?? 'https://app-dev2.simetrik.com/',
  },
  versionApp: process.env.VERSION_APP ?? '',
  staticsUrl: process.env.STATICS_URL ?? 'https://statics.simetrik.com',
  apiUrl: process.env.API_URL ?? '',
  urlRequestConnection: process.env.URL_REQUEST_CONNECTION ?? '',
  workspaceCookieName: process.env.WORKSPACE_COOKIE_NAME ?? 'wpSettingDev',
  auth0CookieDomain: process.env.AUTH0_COOKIE_DOMAIN ?? 'localhost',
};

export default environmentConstant;
